import { createSlice } from '@reduxjs/toolkit';

import { ModalsReducer } from './Modals.model';

const initialState: ModalsReducer = {
  displayChangeVersion: false,
  content: null,
};

export const modalsSlice = createSlice({
  name: 'modalsReducer',
  initialState,
  reducers: {
    SET_DISPLAY_CHANGE_VERSION(state, action) {
      return {
        ...state,
        displayChangeVersion: action.payload,
      };
    },
    SET_CONTENT_MODAL(state, action) {
      return {
        ...state,
        content: action.payload,
      };
    },
  },
});

export const { SET_DISPLAY_CHANGE_VERSION, SET_CONTENT_MODAL } = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
