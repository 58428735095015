import { createSlice } from '@reduxjs/toolkit';

import { AnalyticsReducer } from './Analytics.model';

const initialState: AnalyticsReducer = {
  generalAnalytics: {
    documentsView: null,
    visitsCountry: [],
    visitsSummary: null,
    documentsTopSearches: [],
    coursesTopSearches: [],
    usersLanguage: [],
  },
  documentsAnalytics: {
    documentsView: [],
    total: 0,
    published: 0,
    archived: 0,
    types: [],
    origin: [],
    scope: [],
    language: [],
    categories: {
      applicationDomains: [],
      scientificDiscipline: [],
      technicsAndMethods: [],
    },
  },
  elearningAnalytics: {
    totalCourse: 0,
    publishedCourse: 0,
    archivedCourse: 0,
    totalLearningPath: 0,
    publishedLearningPath: 0,
    archivedLearningPath: 0,
    totalFollowedCourses: 0,
    averageScoreQuizzes: 0,
    averageTimeNeeded: 0,
    totalFollowedLearningPaths: 0,
    activeUsersPerCourse: [],
    mostFollowedCourses: [],
    mostFollowedLearningPaths: [],
    courseCategories: {
      applicationDomains: [],
      scientificDiscipline: [],
      technicsAndMethods: [],
    },
    learningPathCategories: {
      applicationDomains: [],
      scientificDiscipline: [],
      technicsAndMethods: [],
    },
  },
};

export const analyticsSlice = createSlice({
  name: 'analyticsReducer',
  initialState,
  reducers: {
    SET_GENERAL_ANALYTICS(state, action) {
      return {
        ...state,
        generalAnalytics: action.payload,
      };
    },
    SET_DOCUMENTS_ANALYTICS(state, action) {
      return {
        ...state,
        documentsAnalytics: action.payload,
      };
    },
    SET_ELEARNING_ANALYTICS(state, action) {
      return {
        ...state,
        elearningAnalytics: action.payload,
      };
    },
  },
});

export const { SET_GENERAL_ANALYTICS, SET_DOCUMENTS_ANALYTICS, SET_ELEARNING_ANALYTICS } = analyticsSlice.actions;

export const analyticsReducer = analyticsSlice.reducer;
