import { createInstance } from '@datapunt/matomo-tracker-react';
import { UppyOptions } from '@uppy/core';

export const sessionLength = 900000;
export const timeBeforeForceLogout = sessionLength * 2;
export const videosFileTypes = ['.mp4', '.avi', '.mkv', '.webm'];
export const csvFileTypes = ['.doc', '.docx', '.xls', '.xlsm', '.csv'];
export const imageFileTypes = ['.jpg', '.jpeg', '.png'];
export const algolia = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '',
  documentsIndName: process.env.NEXT_PUBLIC_ALGOLIA_IND_DOCUMENTS || 'documents_dev',
  coursesIndName: process.env.NEXT_PUBLIC_ALGOLIA_IND_COURSES || 'courses_dev',
  discussionsIndName: process.env.NEXT_PUBLIC_ALGOLIA_IND_DISCUSSIONS || 'discussions_dev',
};

export const variables = {
  debounceCooldown: 1000,
};

export const matomoInstance = createInstance({
  urlBase: process.env.NEXT_PUBLIC_MATOMO_CLOUD_URL || 'https://secbio.matomo.cloud',
  siteId: 1,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
});

export const supertokensConfig = {
  // URL where supertokens core is hosted.
  // see https://supertokens.io/docs/emailpassword/quick-setup/core/with-docker
  appName: 'SecBio',
  websiteDomain: process.env.NEXT_PUBLIC_FRONT_URL,
  apiDomain: process.env.NEXT_PUBLIC_API_URL,
};

export const convertApiConfig = {
  apiSecretKey: process.env.THUMBNAIL_API_SECRET,
};

export const documentUppyConfig: UppyOptions = {
  autoProceed: true,
  restrictions: {
    maxFileSize: 31457280,
    maxNumberOfFiles: 1,
    minNumberOfFiles: null,
    allowedFileTypes: ['.pdf'],
  },
};
export const imageUppyConfig: UppyOptions = {
  autoProceed: true,
  restrictions: {
    maxFileSize: 5120000,
    maxNumberOfFiles: 1,
    minNumberOfFiles: null,
    allowedFileTypes: ['.jpg', '.jpeg', '.png'],
  },
};
export const courseMediaUppyConfig: UppyOptions = {
  autoProceed: false,
  restrictions: {
    maxFileSize: 419430400,
    maxNumberOfFiles: 25,
    minNumberOfFiles: null,
    allowedFileTypes: [].concat(imageFileTypes, videosFileTypes, csvFileTypes),
  },
};
export const additionalResourcesUppyConfig: UppyOptions = {
  autoProceed: false,
  restrictions: {
    maxFileSize: 31457280,
    maxNumberOfFiles: 10,
    minNumberOfFiles: null,
    allowedFileTypes: ['.pdf', 'image/*'],
  },
};

export const uppyConfig = { limit: 4, timeout: 1000 * 60 * 3 };
