import { createSlice } from '@reduxjs/toolkit';

import { DiscussionReducer } from './Discussions.model';

const initialState: DiscussionReducer = {
  discussions: null,
  oneDiscussion: null,
};

export const discussionSlice = createSlice({
  name: 'discussionReducer',
  initialState,
  reducers: {
    SET_DISCUSSIONS(state, action) {
      return {
        ...state,
        discussions: action.payload,
      };
    },
    SET_ONE_DISCUSSION(state, action) {
      return {
        ...state,
        oneDiscussion: action.payload,
      };
    },
  },
});

export const { SET_DISCUSSIONS, SET_ONE_DISCUSSION } = discussionSlice.actions;

export const discussionReducer = discussionSlice.reducer;
