import { DocumentStatuses, RefusalReasons } from '@sbio/interfaces';

import { IDocument, IVersionsQueryValidator } from 'store/documents/Documents.model';
import { IFolderNode } from '../../layouts/personal/MyLibrary/MyLibrary.component';
import { apiSdk } from '../activateApi';
import ApiRes from '../ApiRes';

export const apiDocuments = {
  getDocumentsId: async (id: string, signedUrl: boolean) => {
    // @ts-expect-error: TODO:
    return await ApiRes.customFetch(() => apiSdk.getDocumentsId({ id, signedUrl }, {}));
  },

  getFeaturedDocuments: async () => {
    return await ApiRes.customFetch(() =>
      apiSdk.getDocuments(
        {},
        {},
        {
          params: {
            featured: true,
            status: DocumentStatuses.ACTIVE,
          },
        },
      ),
    );
  },


  getVersionsId: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.getVersionsId({ id }));
  },

  getValidatedVersions: async (query: IVersionsQueryValidator) => {
    return await ApiRes.customFetch(() =>
      apiSdk.getVersions([
        { name: 'document', value: query.document, in: 'query' },
        { name: 'status', value: query.status, in: 'query' },
      ]),
    );
  },

  getThumbnailsDoc: async (listOfIds: string[]) => {
    //TODO: use API SDK instead of fetch
    const apiUrl = process.env.NEXT_PUBLIC_API_URL;
    // console.log("APISDK", apiUrl)
    // return await ApiRes.customFetch(() => apiSdk.postThumbnailsDocs())
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      'listOfIds': listOfIds,
    });

    console.log('raw', raw);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await fetch(apiUrl + '/documents/thumbnails', requestOptions).then((response) => response.text());
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
  },

  getDocumentStats: async () => {
    console.log('Get Document API call');
    return await ApiRes.customFetch(() => apiSdk.getDocumentsStatistics());
  },
  increaseDownloadNumber: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.patchDocumentsIdDownload({ id }, {}));
  },
  createDocument: async (data: IDocument) => {
    return await ApiRes.customFetch(() => apiSdk.postDocuments({}, data, {}));
  },
  createVersion: async (data: any) => {
    return await ApiRes.customFetch(() => apiSdk.postVersions({}, data));
  },
  archiveVersion: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.patchVersionsIdArchive({ id }, {}));
  },
  restoreVersion: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.patchVersionsIdRestore({ id }, {}));
  },
  deleteDocument: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.deleteDocumentsId({ id }, {}));
  },
  deleteVersion: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.deleteVersionsId({ id }, {}));
  },
  archiveDocument: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.patchDocumentsIdArchive({ id }, {}));
  },
  createDocumentRevision: async (body: any) => {
    return await ApiRes.customFetch(() => apiSdk.postRevisionsDocuments({}, body, {}));
  },
  updateDocumentRevision: async (id: string, body: any) => {
    return await ApiRes.customFetch(() => apiSdk.putRevisionsIdDocuments({ id }, body, {}));
  },
  // TODO: check if we still use it
  approveRevision: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.putRevisionsIdApproval({ id }, { approved: true }));
  },
  // TODO: check if we still use it
  declineRevision: async (id: string, refusal_reasons: RefusalReasons) => {
    return await ApiRes.customFetch(() =>
      apiSdk.putRevisionsIdApproval({ id }, { approved: false, refusalReasons: refusal_reasons }),
    );
  },
  deleteRevision: async (id: string) => {
    return await ApiRes.customFetch(() =>
      apiSdk.putRevisionsIdDelete({ id }, { approved: false, refusalReasons: RefusalReasons.DELETED }),
    );
  },
  revisionApprovement: async (id: string, approved: boolean, refusal_reasons?: RefusalReasons) => {
    if (refusal_reasons) {
      return await ApiRes.customFetch(() =>
        apiSdk.putRevisionsIdApproval({ id }, { approved: approved, refusalReasons: refusal_reasons }),
      );
    } else {
      return await ApiRes.customFetch(() => apiSdk.putRevisionsIdApproval({ id }, { approved: approved }));
    }
  },
  addToLibrary: async (idDocument: string) => {
    const body: any = { document: idDocument };
    return await ApiRes.customFetch(() => apiSdk.postLibraryMe({}, body));
  },
  updateLibrary: async (fileSystem: IFolderNode, documents: string[]) => {
    const body: any = { fileSystem, documents };
    return await ApiRes.customFetch(() => apiSdk.putLibraryMe({}, body));
  },
  removeFromLibrary: async (idDocument: string) => {
    return await ApiRes.customFetch(() => apiSdk.deleteLibraryMe({}, { document: idDocument }));
  },
  getLibrary: async () => {
    return await ApiRes.customFetch(() => apiSdk.getLibraryMe());
  },
  updateDocumentOwner: async (id: string, idNewOwner: string) => {
    return await ApiRes.customFetch(() => apiSdk.patchDocumentsIdOwner({ id }, { idUser: idNewOwner }));
  },
  makeFeatured: async (id: string, featured: boolean, endDate?: Date) => {
    return await ApiRes.customFetch(() => apiSdk.patchDocumentsIdFeatured({ id }, { featured, endDate }));
  },

  updatePendingDocument: async (id: string, data: any) => {
    return await ApiRes.customFetch(() => apiSdk.putRevisionsIdDocuments({ id }, data));
  },

  handleLikeReaction: async (id: string, userId) => {
    return await ApiRes.customFetch(() => apiSdk.patchDocumentsIdLikereaction({ id, userId }));
  },
  getMostViewedDocuments: async () => {
    return await ApiRes.customFetch(() =>
      apiSdk.getDocuments(
        {},
        {},
        {
          params: {
            sortByDownloads: true,
            status: DocumentStatuses.ACTIVE,
          },
        },
      ),
    );
  },
  getRecentDocuments: async () => {
    return await ApiRes.customFetch(() =>
      apiSdk.getDocuments(
        {},
        {},
        {
          params: {
            sortByPublishedDate: true,
            status: DocumentStatuses.ACTIVE,
          },
        },
      ),
    );
  },

  getDocumentArchive: async (listOfIds: string[]) => {
    const apiUrl = process.env.NEXT_PUBLIC_API_URL;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        'listOfIds': listOfIds,
      }),
    };

    return await fetch(apiUrl + '/documents/get-archive', requestOptions).then((response) => response);
  },
};
