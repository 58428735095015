import { createSlice } from '@reduxjs/toolkit';

import { ReportsReducer } from './Reports.model';

const initialState: ReportsReducer = {
  report: null,
  reports: null,
};

export const reportsSlice = createSlice({
  name: 'reportsReducer',
  initialState,
  reducers: {
    SET_REPORT(state, action) {
      return {
        ...state,
        report: action.payload,
      };
    },
    SET_REPORTS(state, action) {
      return {
        ...state,
        reports: action.payload,
      };
    },
  },
});

export const { SET_REPORT, SET_REPORTS } = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
