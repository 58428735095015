"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.LearningPathStatuses = void 0;
var LearningPathStatuses;
exports.LearningPathStatuses = LearningPathStatuses;
(function(LearningPathStatuses) {
    LearningPathStatuses["PENDING"] = 'pending';
    LearningPathStatuses["ACTIVE"] = 'active';
    LearningPathStatuses["REFUSED"] = 'refused';
    LearningPathStatuses["ARCHIVED"] = 'archived';
})(LearningPathStatuses || (exports.LearningPathStatuses = LearningPathStatuses = {}));
