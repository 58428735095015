import { createSlice } from '@reduxjs/toolkit';

import { IHistoryReducer } from './History.model';

const initialState: IHistoryReducer = {
  historyLog: null,
  history: null,
};

export const historySlice = createSlice({
  name: 'historyReducer',
  initialState,
  reducers: {
    SET_HISTORY_LOG(state, action) {
      return {
        ...state,
        historyLog: action.payload,
      };
    },
    SET_HISTORY(state, action) {
      return {
        ...state,
        history: action.payload,
      };
    },
  },
});

export const { SET_HISTORY, SET_HISTORY_LOG } = historySlice.actions;

export const historyReducer = historySlice.reducer;
