import { createSlice } from '@reduxjs/toolkit';

import { NotificationsReducer } from './Notifications.model';

export const initialState: NotificationsReducer = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notificationsReducer',
  initialState,
  reducers: {
    SET_NOTIFICATIONS(state, action) {
      return {
        ...state,
        notifications: action.payload,
      };
    },
  },
});

export const { SET_NOTIFICATIONS } = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
