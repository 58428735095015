import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from './languages';

const options = {
  order: ['querystring', 'localStorage', 'sessionStorage', 'path', 'navigator'], // querystring : ?lng=fr|en
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'fr',
    detection: options,

    // keySeparator: '.', // we do not use keys in form messages.welcome

    // interpolation: {
    // escapeValue: false // react already safes from xss
    // }
  });

export default i18n;
