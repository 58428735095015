"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.AnalyticType = void 0;
var AnalyticType;
exports.AnalyticType = AnalyticType;
(function(AnalyticType) {
    AnalyticType["ALL"] = 'all';
    AnalyticType["DOCUMENTS"] = 'documents';
    AnalyticType["ELEARNING"] = ' elearning';
    AnalyticType["DISCUSSION"] = 'discussions';
})(AnalyticType || (exports.AnalyticType = AnalyticType = {}));
