import { createSlice } from '@reduxjs/toolkit';

import { SharedReducer } from './Shared.model';

const initialState: SharedReducer = {
  isLoading: false,
  isLoadingVersions: false,
  refreshHitsResult: false,
};

export const sharedSlice = createSlice({
  name: 'sharedReducer',
  initialState,
  reducers: {
    SET_IS_LOADING(state, action) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    SET_IS_LOADING_VERSIONS(state, action) {
      return {
        ...state,
        isLoadingVersions: action.payload,
      };
    },
    SET_REFRESH_HITS_RESULT(state, action) {
      return {
        ...state,
        refreshHitsResult: action.payload,
      };
    },
  },
});

export const { SET_IS_LOADING, SET_IS_LOADING_VERSIONS, SET_REFRESH_HITS_RESULT } = sharedSlice.actions;

export const sharedReducer = sharedSlice.reducer;
