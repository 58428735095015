"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.SecBioEntityType = exports.SecBioAction = void 0;
var SecBioAction;
exports.SecBioAction = SecBioAction;
(function(SecBioAction) {
    SecBioAction["CREATE"] = "CREATE";
    SecBioAction["UPDATE"] = "UPDATE";
    SecBioAction["DELETE"] = "DELETE";
    SecBioAction["PUBLISH"] = "PUBLISH";
    SecBioAction["APPROVE"] = "APPROVE";
    SecBioAction["ARCHIVE"] = "ARCHIVE";
    SecBioAction["CREATE_COURSE_DRAFT"] = "CREATE_COURSE_DRAFT";
    SecBioAction["UPDATE_COURSE_DRAFT"] = "UPDATE_COURSE_DRAFT";
    SecBioAction["INVITE"] = "INVITE";
    SecBioAction["BAN"] = "BAN";
    SecBioAction["REMOVE_PERMISSION"] = "REMOVE_PERMISSION";
    SecBioAction["ADD_PERMISSION"] = "ADD_PERMISSION";
    SecBioAction["MAKE_FEATURED"] = "MAKE_FEATURED";
    SecBioAction["UPDATE_OWNERS"] = "UPDATE_OWNERS";
    SecBioAction["ADD_TO_LIBRARY"] = "ADD_TO_LIBRARY";
    SecBioAction["REMOVE_FROM_LIBRARY"] = "REMOVE_FROM_LIBRARY";
})(SecBioAction || (exports.SecBioAction = SecBioAction = {}));
var SecBioEntityType;
exports.SecBioEntityType = SecBioEntityType;
(function(SecBioEntityType) {
    SecBioEntityType["DOCUMENT"] = 'Document';
    SecBioEntityType["COURSE"] = 'Course';
    SecBioEntityType["LEARNING_PATH"] = 'LearningPath';
    SecBioEntityType["REVISION"] = 'Revision';
    SecBioEntityType["USER"] = 'User';
    SecBioEntityType["DISCUSSION"] = 'Discussion';
})(SecBioEntityType || (exports.SecBioEntityType = SecBioEntityType = {}));
