import { createSlice } from '@reduxjs/toolkit';

import { UserReducer } from './User.model';

export const initialState: UserReducer = {
  accountActivated: false,
  activationUserId: null,
  userInfoActivated: false,
  userPasswordActivated: false,
  userData: null,
  onboardedUser: null,
  forgotPasswordEmailFound: null,
  passwordReset: false,
  onboardingStatus: null,
  showResendInvitation: false,
  showTwoFAVerification: false,
};

export const userSlice = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    SET_RESEND_INVITATION(state, action) {
      return {
        ...state,
        showResendInvitation: action.payload,
      };
    },
    SET_ACCOUNT_ACTIVATED(state, action) {
      return {
        ...state,
        accountActivated: action.payload,
      };
    },
    SET_ACTIVATION_USERID(state, action) {
      return {
        ...state,
        activationUserId: action.payload,
      };
    },
    SET_USER_INFO_ACTIVATED(state, action) {
      return {
        ...state,
        userInfoActivated: action.payload,
      };
    },
    SET_USER_PASSWORD_ACTIVATED(state, action) {
      return {
        ...state,
        userPasswordActivated: action.payload,
      };
    },
    SET_CURRENT_USER(state, action) {
      return {
        ...state,
        userData: action.payload,
      };
    },
    SET_ONBOARDED_USER(state, action) {
      return {
        ...state,
        onboardedUser: action.payload,
      };
    },
    SET_FORGOT_PASSWORD_FOUND(state, action) {
      return {
        ...state,
        forgotPasswordEmailFound: action.payload,
      };
    },
    SET_PASSWORD_RESET(state, action) {
      return {
        ...state,
        passwordReset: action.payload,
      };
    },
    SET_ONBOARDING_STATUS(state, action) {
      return {
        ...state,
        onboardingStatus: action.payload,
      };
    },

    SET_2FA_VERIFICATION(state, action) {
      return {
        ...state,
        showTwoFAVerification: action.payload,
      };
    },
  },
});

export const {
  SET_ACCOUNT_ACTIVATED,
  SET_ACTIVATION_USERID,
  SET_USER_INFO_ACTIVATED,
  SET_USER_PASSWORD_ACTIVATED,
  SET_ONBOARDED_USER,
  SET_CURRENT_USER,
  SET_FORGOT_PASSWORD_FOUND,
  SET_PASSWORD_RESET,
  SET_ONBOARDING_STATUS,
  SET_RESEND_INVITATION,
  SET_2FA_VERIFICATION,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
