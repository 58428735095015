"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Unauthorized = exports.Forbidden = exports.BadRequest = exports.SelfHandledErrors = void 0;
var SelfHandledErrors;
exports.SelfHandledErrors = SelfHandledErrors;
(function(SelfHandledErrors) {
    SelfHandledErrors["RESOURCE_NOT_FOUND"] = "RESOURCE_NOT_FOUND";
    SelfHandledErrors["DUPLICATE_FIELD_VALUE_ENTERED"] = "DUPLICATE_FIELD_VALUE_ENTERED";
    SelfHandledErrors["USER_ALREADY_ACTIVATED"] = "USER_ALREADY_ACTIVATED";
})(SelfHandledErrors || (exports.SelfHandledErrors = SelfHandledErrors = {}));
var BadRequest;
exports.BadRequest = BadRequest;
(function(BadRequest) {
    BadRequest["CANNOT_ARCHIVE_INACTIVE_DOCUMENT"] = "CANNOT_ARCHIVE_INACTIVE_DOCUMENT";
    BadRequest["CANNOT_MAKE_FEATURED_INACTIVE_DOCUMENT"] = "CANNOT_MAKE_FEATURED_INACTIVE_DOCUMENT";
    BadRequest["UNSUPPORTED_FILE_FORMAT"] = "UNSUPPORTED_FILE_FORMAT";
    BadRequest["BAD_REQUEST_DEFAULT_ERROR"] = "BAD_REQUEST_DEFAULT_ERROR";
    BadRequest["APPROVAL_INVALID_RESOURCE_TYPE"] = "APPROVAL_INVALID_RESOURCE_TYPE";
    BadRequest["INVALID_ONBOARDING_TOKEN"] = "INVALID_ONBOARDING_TOKEN";
    BadRequest["COULDNOT_VERIFY_OLD_USER_PASSWORD"] = "COULDNOT_VERIFY_OLD_USER_PASSWORD";
    BadRequest["EMAIL_SENT_TOO_RECENTLY"] = "EMAIL_SENT_TOO_RECENTLY";
})(BadRequest || (exports.BadRequest = BadRequest = {}));
var Forbidden;
exports.Forbidden = Forbidden;
(function(Forbidden) {
    Forbidden["INSUFFICIENT_SCOPE"] = "INSUFFICIENT_SCOPE";
    Forbidden["RESOURCE_ARCHIVED"] = "RESOURCE_ARCHIVED";
    Forbidden["OUT_ORG_PERIMETER"] = "OUT_ORG_PERIMETER";
})(Forbidden || (exports.Forbidden = Forbidden = {}));
var Unauthorized;
exports.Unauthorized = Unauthorized;
(function(Unauthorized) {
    Unauthorized["CANNOT_RESET_PASSWORD_WHILE_NOTFORGOTTEN"] = "CANNOT_RESET_PASSWORD_WHILE_NOTFORGOTTEN";
    Unauthorized["CANNOT_LOGIN_ACCOUNT_NOT_CONFIRMED"] = "CANNOT_LOGIN_ACCOUNT_NOT_CONFIRMED";
    Unauthorized["CANNOT_LOGIN_USER_ARCHIVED"] = "CANNOT_LOGIN_USER_ARCHIVED";
    Unauthorized["CANNOT_LOGIN_USER_BLOCKED"] = "CANNOT_LOGIN_USER_BLOCKED";
    Unauthorized["CANNOT_LOGIN_WRONG_EMAIL_PASSWORD"] = "CANNOT_LOGIN_WRONG_EMAIL_PASSWORD";
    Unauthorized["UNAUTHORIZED_DEFAULT_ERROR"] = "UNAUTHORIZED_DEFAULT_ERROR";
    Unauthorized["TWO_FA_REQUIRED"] = "TWO_FA_REQUIRED";
    Unauthorized["TWO_FA_CODE_EXPIRED"] = "TWO_FA_CODE_EXPIRED";
    Unauthorized["TWO_FA_BAD_CODE"] = "TWO_FA_BAD_CODE";
    Unauthorized["CAPTCHA_ERROR"] = "CAPTCHA_ERROR";
})(Unauthorized || (exports.Unauthorized = Unauthorized = {}));
