import { Client as ApiClient } from 'api-sdk-types';
import OpenAPIClientAxios from 'openapi-client-axios';
import SuperTokens from 'supertokens-auth-react/lib/build/superTokens';
import Session from 'supertokens-auth-react/recipe/session';

import { supertokensConfig } from '../config/config';
import swagger from '../swagger.json';

export { apiDocuments } from './apis/documents';
export { apiUser } from './apis/user';

export let apiSdk: ApiClient;

const apiUrl = process.env.NEXT_PUBLIC_API_URL;

if (typeof window !== 'undefined') {
  SuperTokens.init({
    appInfo: {
      appName: supertokensConfig.appName,
      apiDomain: supertokensConfig.apiDomain,
      websiteDomain: supertokensConfig.websiteDomain,
    },
    recipeList: [Session.init()],
  });
}

export async function activateApi(): Promise<void> {
  if (apiSdk !== undefined) {
    console.debug('API SDK was already activated');
    return;
  }

  const api = new OpenAPIClientAxios({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    definition: swagger,
    withServer: { url: apiUrl },
  });

  apiSdk = await api.init<ApiClient>();

  // We add supertokens axios interceptor so it can add auth header to all incoming requests
  // But also handle all the logic to set cookies into the browser.
  // @see https://supertokens.io/docs/session/quick-setup/frontend#3%EF%B8%8F-add-axios-session-interceptor
  Session.addAxiosInterceptors(apiSdk);
}
