"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ReportCategories = void 0;
var ReportCategories;
exports.ReportCategories = ReportCategories;
(function(ReportCategories) {
    ReportCategories["CONTENT"] = 'content';
    ReportCategories["SPELLING"] = 'spelling';
    ReportCategories["OTHER"] = 'other';
})(ReportCategories || (exports.ReportCategories = ReportCategories = {}));
