"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ExtractedTextStatuses = void 0;
var ExtractedTextStatuses;
exports.ExtractedTextStatuses = ExtractedTextStatuses;
(function(ExtractedTextStatuses) {
    ExtractedTextStatuses["PENDING"] = 'pending';
    ExtractedTextStatuses["COMPLETED"] = 'completed';
})(ExtractedTextStatuses || (exports.ExtractedTextStatuses = ExtractedTextStatuses = {}));
