import { combineReducers } from 'redux';

import { adminReducer } from './admin/Admin.reducer';
import { analyticsReducer } from './analytics/Analytics.reducers';
import { classesReducer } from './classes/Classes.reducers';
import { courseEditorReducer } from './courseEditor/CourseEditor.reducers';
import { discussionReducer } from './discussions/Discussions.reducers';
import { documentsReducer } from './documents/Documents.reducers';
import { elearningReducer } from './elearning/Elearning.reducers';
import { errorsReducer } from './errors/Errors.reducer';
import { historyReducer } from './history/History.reducers';
import { modalsReducer } from './modals/Modals.reducers';
import { notificationsReducer } from './notifications/Notifications.reducers';
import { optionalDocumentAttributesReducer } from './optionalDocumentAttributes/OptionalDocumentAttributes.reducers';
import { organizationsReducer } from './organizations/Organizations.reducers';
import { reportsReducer } from './reports/Reports.reducers';
import { revisionsReducer } from './revisions/Revisions.reducers';
import { sharedReducer } from './shared/Shared.reducers';
import { userReducer } from './user/User.reducers';

export interface IPaginationWrap {
  totalDocs?: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  offset: number;
  page: number;
  pagingCounter: number;
  prevPage: number | null;
  totalPages: number;
}

export const rootReducers = combineReducers({
  documentsReducer,
  discussionReducer,
  userReducer,
  sharedReducer,
  modalsReducer,
  adminReducer,
  errorsReducer,
  elearningReducer,
  classesReducer,
  courseEditorReducer,
  revisionsReducer,
  historyReducer,
  reportsReducer,
  notificationsReducer,
  analyticsReducer,
  organizationsReducer,
  optionalDocumentAttributesReducer,
});

export type AppState = ReturnType<typeof rootReducers>;
