"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ClassStatuses = void 0;
var ClassStatuses;
exports.ClassStatuses = ClassStatuses;
(function(ClassStatuses) {
    ClassStatuses["ACTIVE"] = 'active';
    ClassStatuses["COMPLETED"] = 'completed';
    ClassStatuses["CANCELED"] = 'canceled';
})(ClassStatuses || (exports.ClassStatuses = ClassStatuses = {}));
