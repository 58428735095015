import { createSlice } from '@reduxjs/toolkit';

import { IClassesReducer } from './Classes.model';

const initialState: IClassesReducer = {
  oneClass: null,
  classes: null,
  scoreInfos: { score: 0, history: null },
  classesStatistic:{
    active: 0,
    total: 0,
    followed: 0,
  },
  classesPerformance:{
    averageCompletionScore: 0,
    averageTimeNeeded: 0,
  }
};

export const classesSlice = createSlice({
  name: 'classesReducer',
  initialState,
  reducers: {
    SET_CLASSES(state, action) {
      return {
        ...state,
        classes: action.payload,
      };
    },
    SET_ONE_CLASS(state, action) {
      return {
        ...state,
        oneClass: action.payload,
      };
    },
    SET_CLASSES_STATISTIC(state, action) {
      return {
        ...state,
        classesStatistic: action.payload.classesCount,
        classesPerformance: action.payload.classesPerformance,
      };
    },
    SET_SCORE_INFOS(state, action) {
      return {
        ...state,
        scoreInfos: action.payload,
      };
    },
  },
});

export const { SET_CLASSES, SET_ONE_CLASS, SET_CLASSES_STATISTIC, SET_SCORE_INFOS } = classesSlice.actions;

export const classesReducer = classesSlice.reducer;
