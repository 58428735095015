import React from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { Snackbar as SnackbarProps } from 'store/errors/Errors.model';

import styles from './Snackbar.module.scss';

interface SnackBarProps {
  t: (tranlation: string) => string;
  snackbar: SnackbarProps;
  setSnackBar: (snackbarInfos: SnackbarProps) => void;
}

const CustomSnackbar: React.FC<SnackBarProps> = ({ snackbar, setSnackBar, t }) => {
  const dispatch = useDispatch();

  function Alert(props: AlertProps) {
    return <MuiAlert variant="filled" {...props} />;
  }

  // Fallback for untranslated errors

  const eitherDirectOrTranstatedErrorMessage = (error: string) => {
    const isErrorTranslated = t(error) !== error;
    if (isErrorTranslated) {
      return t(error);
    } else {
      return error.split('.').pop();
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar.isDisplayed}
        autoHideDuration={snackbar.duration}
        style={{ zIndex: 10000 }}
        onClose={() => dispatch(setSnackBar({ isDisplayed: false, message: '', type: snackbar.type }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className={styles.snackbar}>
        <Alert
          onClose={() => dispatch(setSnackBar({ isDisplayed: false, message: '', type: snackbar.type }))}
          severity={snackbar.type}>
          <p>{eitherDirectOrTranstatedErrorMessage(snackbar.message)}</p>
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomSnackbar;
