import { createSlice } from '@reduxjs/toolkit';

import { ICourseEditorReducer } from './CourseEditor.model';

const initialState: ICourseEditorReducer = {
  selectedChapterEdition: 0,
  courseContentInEdition: [],
  editingProgressSaved: true,
  draft: null,
};

export const courseEditorSlice = createSlice({
  name: 'courseEditorReducer',
  initialState,
  reducers: {
    SET_CONTENT_IN_EDITION(state, action) {
      return {
        ...state,
        editingProgressSaved: true,
        courseContentInEdition: action.payload,
      };
    },
    SET_SELECTED_CHAPTER_EDITION(state, action) {
      return {
        ...state,
        selectedChapterEdition: action.payload,
      };
    },
    SET_DRAFT(state, action) {
      return {
        ...state,
        draft: action.payload,
      };
    },
    SET_PROGRESS_SAVED(state, action) {
      return {
        ...state,
        editingProgressSaved: action.payload,
      };
    },
  },
});

export const { SET_CONTENT_IN_EDITION, SET_SELECTED_CHAPTER_EDITION, SET_PROGRESS_SAVED, SET_DRAFT } =
  courseEditorSlice.actions;

export const courseEditorReducer = courseEditorSlice.reducer;
