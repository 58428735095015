import { createSlice } from '@reduxjs/toolkit';

import { IElearningReducer } from './Elearning.model';

const initialState: IElearningReducer = {
  course: null,
  newCourses: null,
  learningPath: null,
  newLearningPaths: null,
  training: null,
  trainings: null,
  thumbnails: [],
  oneCourseStats: null,
  mostPopularCourses: [],
  mostPopularLearningPaths: [],
  classesStatistic: {
    active: 0,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    total: 0,
    followed: 0,
  },
  classesPerformance: {
    averageCompletionScore: 0,
    averageTimeNeeded: 0,
  },
};

export const elearningSlice = createSlice({
  name: 'elearningReducer',
  initialState,
  reducers: {
    SET_COURSE(state, action) {
      return {
        ...state,
        course: action.payload,
      };
    },
    SET_NEW_COURSES(state, action) {
      return {
        ...state,
        newCourses: action.payload,
      };
    },
    SET_THUMBNAILS(state, action) {
      return {
        ...state,
        thumbnails: action.payload,
      };
    },
    SET_LEARNING_PATH(state, action) {
      return {
        ...state,
        learningPath: action.payload,
      };
    },
    SET_NEW_LEARNING_PATHS(state, action) {
      return {
        ...state,
        newLearningPaths: action.payload,
      };
    },
    SET_TRAINING(state, action) {
      return {
        ...state,
        training: action.payload,
      };
    },
    SET_TRAININGS(state, action) {
      return {
        ...state,
        trainings: action.payload,
      };
    },
    SET_COURSE_STATISTIC(state, action) {
      return {
        ...state,
        classesStatistic: action.payload.classesCount,
        classesPerformance: action.payload.classesPerformance,
      };
    },
    SET_MOST_POPULAR_COURSES(state, action) {
      return {
        ...state,
        mostPopularCourses: action.payload,
      };
    },
    SET_ONE_COURSE_STATS(state, action) {
      return {
        ...state,
        oneCourseStats: action.payload,
      };
    },
    SET_MOST_POPULAR_LEARNING_PATHS(state, action) {
      return {
        ...state,
        mostPopularLearningPaths: action.payload,
      };
    },
  },
});

export const {
  SET_COURSE,
  SET_NEW_COURSES,
  SET_LEARNING_PATH,
  SET_NEW_LEARNING_PATHS,
  SET_TRAINING,
  SET_TRAININGS,
  SET_COURSE_STATISTIC,
  SET_THUMBNAILS,
  SET_MOST_POPULAR_COURSES,
  SET_MOST_POPULAR_LEARNING_PATHS,
  SET_ONE_COURSE_STATS,
} = elearningSlice.actions;

export const elearningReducer = elearningSlice.reducer;
