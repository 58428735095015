"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.listEnumValues = exports.SortIndexPrefixes = exports.RevisionTypes = exports.RevisionStatuses = void 0;
var RevisionStatuses;
exports.RevisionStatuses = RevisionStatuses;
(function(RevisionStatuses) {
    RevisionStatuses["PENDING"] = 'pending';
    RevisionStatuses["APPROVED"] = 'approved';
    RevisionStatuses["DECLINED"] = 'declined';
    RevisionStatuses["DRAFT"] = 'draft';
})(RevisionStatuses || (exports.RevisionStatuses = RevisionStatuses = {}));
var RevisionTypes;
exports.RevisionTypes = RevisionTypes;
(function(RevisionTypes) {
    RevisionTypes["VERSION"] = 'VersionRevision';
    RevisionTypes["COURSE"] = 'CourseRevision';
    RevisionTypes["LEARNING_PATH"] = 'LearningPathRevision';
})(RevisionTypes || (exports.RevisionTypes = RevisionTypes = {}));
var SortIndexPrefixes;
exports.SortIndexPrefixes = SortIndexPrefixes;
(function(SortIndexPrefixes) {
    SortIndexPrefixes["CREATED_AT_DESC"] = 'createdAt_desc';
    SortIndexPrefixes["CREATED_AT_ASC"] = 'createdAt_asc';
    SortIndexPrefixes["PUBLISHED_AT_DESC"] = 'publishedAt_desc';
    SortIndexPrefixes["PUBLISHED_AT_ASC"] = 'publishedAt_asc';
    SortIndexPrefixes["TITLE_DESC"] = 'title_desc';
    SortIndexPrefixes["TITLE_ASC"] = 'title_asc';
    SortIndexPrefixes["RELEVANCE"] = 'relevance';
    SortIndexPrefixes["SCORE_DESC"] = 'score_desc';
    SortIndexPrefixes["SCORE_ASC"] = 'score_asc';
    SortIndexPrefixes["LIKES_ASC"] = 'likes_asc';
    SortIndexPrefixes["LIKES_DESC"] = 'likes_desc';
})(SortIndexPrefixes || (exports.SortIndexPrefixes = SortIndexPrefixes = {}));
const listEnumValues = function(enumToList) {
    return Object.values(enumToList).filter((x)=>typeof x === 'string'
    );
};
exports.listEnumValues = listEnumValues;
