export const pluralize = (text: string, number: number, suffix: string): string => {
  if (number > 1) {
    return text + suffix;
  } else {
    return text;
  }
};

export const upFChar = (text: string): string => {
  if (text != '' && text != undefined) {
    let letter = text.charAt(0);
    letter = letter.toUpperCase();
    const rest = text.slice(1);
    return letter + rest;
  } else {
    return '';
  }
};
export const downFChar = (text: string): string => {
  if (text != '' && text != undefined) {
    let letter = text.charAt(0);
    letter = letter.toLowerCase();
    const rest = text.slice(1);
    return letter + rest;
  } else {
    return '';
  }
};
export const cleanSubstring = (text = '', length = 0): string => {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  } else {
    return text;
  }
};
