import { createSlice } from '@reduxjs/toolkit';

import { OptionalDocumentAttributesReducer } from './OptionalDocumentAttributes.model';

export const initialState: OptionalDocumentAttributesReducer = {
  optionalDocumentAttributes: [],
};

export const OptionalDocumentAttributesSlice = createSlice({
  name: 'optionalDocumentAttributesReducer',
  initialState,
  reducers: {
    SET_OPTIONAL_DOCUMENT_ATTRIBUTES(state, action) {
      return {
        ...state,
        optionalDocumentAttributes: action.payload,
      };
    },
  },
});

export const { SET_OPTIONAL_DOCUMENT_ATTRIBUTES } = OptionalDocumentAttributesSlice.actions;

export const optionalDocumentAttributesReducer = OptionalDocumentAttributesSlice.reducer;
