import translationAR from '../../../public/locales/ar/translation.json';
import translationCH from '../../../public/locales/ch/translation.json';
import translationEN from '../../../public/locales/en/translation.json';
import translationES from '../../../public/locales/es/translation.json';
import translationFR from '../../../public/locales/fr/translation.json';
import translationRU from '../../../public/locales/ru/translation.json';

const resources = {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  ch: {
    translation: translationCH,
  },
  es: {
    translation: translationES,
  },
  ar: {
    translation: translationAR,
  },
};
export default resources;
