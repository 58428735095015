'use client';

import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import arSALocale from 'date-fns/locale/ar-SA';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import zhCNLocale from 'date-fns/locale/zh-CN';
import theme from 'styles/CustomMaterialUI';

import 'utils/translation/translation';

import { Snackbar } from 'components/Snackbar';
import { Snack } from 'components/Snackbar/Snackbar.entity';
import { matomoInstance } from 'config/config';
import { getWindowDimensions } from 'hooks/useWindowDimensions';
import { activateApi } from 'services/activateApi';
import { SET_SNACKBAR } from 'store/errors/Errors.reducer';
import store from 'store/store';
import { upFChar } from 'utils/textFormatting';

import 'styles/globals.scss';

const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  ch: zhCNLocale,
  es: esLocale,
  ar: arSALocale,
};

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  // const [serverIsLoading, setServerIsLoading] = useState(false);
  const router = useRouter();

  const { width } = getWindowDimensions();

  const { t } = useTranslation();
  useEffect(() => {
    activateApi();
    const jssStyles = document.getElementById('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (width < 768) {
      store.dispatch(SET_SNACKBAR(new Snack(true, upFChar(t('errors.hardware.RESOLUTION_TOO_SMALL')), 'error', 30000)));
    }
  }, [t, width]);

  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => {
      const isDocRegex = /(\/documents\/[A-Za-z0-9_-]+\/?((\/)|(\?[A-z]+=[A-z]+))?$)/;
      const isCourseRegex = /(\/courses\/[A-Za-z0-9_-]+\/?((\/)|(\?[A-z]+=[A-z]+))?$)/;
      if (window && window._paq && !url.match(isDocRegex) && !url.match(isCourseRegex)) {
        window._paq.push(['setCustomUrl', url]);
        window._paq.push(['setDocumentTitle', document.title.replace(' | SecBio', '')]);
        window._paq.push(['trackPageView']);
      }
    });
    return () => router.events.off('routeChangeComplete', null);
  }, [router.events]);

  const { i18n } = useTranslation();

  const locale = useMemo(() => localeMap[i18n.language] ?? localeMap[1], [i18n.language]);

  useEffect(() => {
    if (t && typeof window !== 'undefined') {
      document.documentElement.lang = i18n.language;

      const isRTL = ['ar', 'he', 'fa'].includes(i18n.language);
      document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
    }
  }, [t, i18n.language]);

  return (
    <Provider store={store}>
      <MatomoProvider value={matomoInstance}>
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            {/* @ts-expect-error: TODO: */}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
              <CssBaseline />
              <Snackbar />
              {/* {!serverIsLoading ? <Component {...pageProps} /> : <Loading />} */}
              <Component {...pageProps} />
            </LocalizationProvider>
          </DndProvider>
        </ThemeProvider>
      </MatomoProvider>
    </Provider>
  );
};

export default MyApp;
