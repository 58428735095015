import { withRouter } from 'next/router';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Snackbar } from 'store/errors/Errors.model';
import { SET_SNACKBAR } from 'store/errors/Errors.reducer';
import { AppState } from 'store/store';
import CustomSnackbar from './Snackbar.component';

interface StateProps {
  snackbar: Snackbar;
}

const mapStateToProps = (state: AppState): StateProps => ({
  snackbar: state.errorsReducer.snackbar,
});

const actionCreators = {
  setSnackBar: SET_SNACKBAR,
};

const enhance = compose(withRouter, withTranslation(), connect(mapStateToProps, actionCreators));

export default enhance(CustomSnackbar) as React.ComponentType;
