import { AxiosResponse } from 'axios';

import { BadRequest, Forbidden, IErrorResponse, SelfHandledErrors, Unauthorized } from '@sbio/interfaces';

import { activateApi, apiSdk } from './activateApi';

export default class ApiRes {
  ok: boolean;

  data: any;

  status: number;

  error: string | undefined;

  constructor(ok: boolean, data: any, status: number, error?: string) {
    this.ok = ok;
    this.status = status;
    this.error = error;
    this.data = data;
  }

  public static async customFetch(endpoint: any, returnRawResponse?: boolean) {
    if (!apiSdk) await activateApi();
    try {
      const res: AxiosResponse<any> = await endpoint();

      if (returnRawResponse) return res;

      return new ApiRes(res.status < 399, res.data, res.status);
    } catch (err: any) {
      const resError = err.response?.data;

      return this.generateError({
        statusCode: resError?.statusCode,
        error: resError?.error,
        message: resError?.message,
        errorData: resError?.errorData,
      });
    }
  }

  static generateError(props: IErrorResponse | any): ApiRes {
    let { error } = props;
    const { statusCode, message, errorData } = props;

    if (errorData) console.log('Error data', errorData); // TODO: see if this data is needed

    if (isApiPredefinedError(props)) {
      error = `errors.${error.charAt(0).toLocaleLowerCase() + error.slice(1)}.${message}`;
      return new ApiRes(statusCode < 400, null, statusCode, error);
    }
    return new ApiRes(statusCode < 400, null, statusCode, error);
  }
}

function isApiPredefinedError(obj: IErrorResponse | any): obj is IErrorResponse {
  return [
    ...Object.values(Unauthorized),
    ...Object.values(BadRequest),
    ...Object.values(SelfHandledErrors),
    ...Object.values(Forbidden),
  ].includes(obj.message);
}
