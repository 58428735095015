import { createSlice } from '@reduxjs/toolkit';

import { ErrorsReducer } from './Errors.model';

const initialState: ErrorsReducer = {
  snackbar: { isDisplayed: false, message: '', type: 'info', duration: 8000 },
};

export const errorsSlice = createSlice({
  name: 'errorsReducer',
  initialState,
  reducers: {
    SET_SNACKBAR(state, action) {
      const serializeSnack = Object.assign({}, action.payload);
      return {
        ...state,
        snackbar: serializeSnack,
      };
    },
  },
});

export const { SET_SNACKBAR } = errorsSlice.actions;

export const errorsReducer = errorsSlice.reducer;
