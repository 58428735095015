import { createSlice } from '@reduxjs/toolkit';

import { DocumentsReducer } from './Documents.model';

const initialState: DocumentsReducer = {
  oneDocument: null,
  oneDocumentVersions: null,
  oneDocumentSelectedVersion: null,
  documentDisplayedId: null,
  documentStatistic: null,
  library: null,
  additionalResources: [],
  featuredDocuments: null,
  thumbnails: [],
  mostViewedDocuments: [],
  recentDocuments: [],
};

export const documentsSlice = createSlice({
  name: 'documentsReducer',
  initialState,
  reducers: {
    SET_ONEDOCUMENT(state, action) {
      return {
        ...state,
        oneDocument: action.payload,
      };
    },
    SET_ONEDOCUMENT_VERSIONS(state, action) {
      return {
        ...state,
        oneDocumentVersions: action.payload,
      };
    },
    SET_ONEDOCUMENT_SELECTED_VERSION(state, action) {
      return {
        ...state,
        oneDocumentSelectedVersion: action.payload,
      };
    },
    SET_DOCUMENT_DISPLAYED_ID(state, action) {
      return {
        ...state,
        documentDisplayedId: action.payload,
      };
    },
    SET_DOCUMENT_STATISTIC(state, action) {
      return {
        ...state,
        documentStatistic: action.payload,
      };
    },
    SET_LIBRARY(state, action) {
      return {
        ...state,
        library: action.payload,
      };
    },
    SET_ADDITIONAL_RESOURCES(state, action) {
      return {
        ...state,
        additionalResources: action.payload,
      };
    },
    ADD_ADDITIONAL_RESOURCES(state, action) {
      return {
        ...state,
        additionalResources: [...state.additionalResources, ...action.payload],
      };
    },
    SET_FEATURED_DOCUMENT(state, action) {
      return {
        ...state,
        featuredDocuments: action.payload,
      };
    },
    SET_THUMBNAILS(state, action) {
      return {
        ...state,
        thumbnails: action.payload,
      };
    },
    SET_MOST_VIEWED_DOCUMENTS(state, action) {
      return {
        ...state,
        mostViewedDocuments: action.payload,
      };
    },
    SET_RECENT_DOCUMENTS(state, action) {
      return {
        ...state,
        recentDocuments: action.payload,
      };
    },
  },
});

export const {
  SET_ONEDOCUMENT,
  SET_ONEDOCUMENT_VERSIONS,
  SET_DOCUMENT_STATISTIC,
  SET_ONEDOCUMENT_SELECTED_VERSION,
  SET_DOCUMENT_DISPLAYED_ID,
  SET_LIBRARY,
  SET_ADDITIONAL_RESOURCES,
  ADD_ADDITIONAL_RESOURCES,
  SET_FEATURED_DOCUMENT,
  SET_MOST_VIEWED_DOCUMENTS,
  SET_THUMBNAILS,
  SET_RECENT_DOCUMENTS,
} = documentsSlice.actions;

export const documentsReducer = documentsSlice.reducer;
