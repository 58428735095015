import { createSlice } from '@reduxjs/toolkit';

import {IOrganization} from "store/organizations/Organizations.model";


const initialState: IOrganization = {
  organizations: [],
};

export const organizationSlice = createSlice({
  name: 'OrganizationReducer',
  initialState,
  reducers: {
    SET_ORGANIZATIONS(state, action) {
      return {
        ...state,
        organizations: action.payload,
      };
    },
  },
});

export const { SET_ORGANIZATIONS } = organizationSlice.actions;

export const organizationsReducer = organizationSlice.reducer;
