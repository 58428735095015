import { Permissions } from '@sbio/interfaces';

import { apiSdk } from '../activateApi';
import ApiRes from '../ApiRes';

export const apiUser = {
  login: async (data: any) => {
    return await ApiRes.customFetch(() => apiSdk.postLogin({}, data));
  },

  twoFAVerification: async (data: any) => {
    return await ApiRes.customFetch(() => apiSdk.postTwofaverification({}, data));
  },

  resendInvitation: async (data: any) => {
    return await ApiRes.customFetch(() => apiSdk.postInvitationresender({}, data));
  },

  cancelInvitation: async (data: any) => {
    return await ApiRes.customFetch(() => apiSdk.postUsersInvitationannulation({}, data));
  },
  approveUser: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.patchUsersIdApproval({ id }));
  },
  getMe: async () => {
    return await ApiRes.customFetch(() => apiSdk.getUsersMe());
  },
  updateUser: async (id: string, data: any) => {
    return await ApiRes.customFetch(() => apiSdk.putUsersId({ id }, data));
  },
  updateMe: async (data: any) => {
    return await ApiRes.customFetch(() => apiSdk.putUsersMe({}, data));
  },

  signUpUser: async (data: any) => {
    return await ApiRes.customFetch(() => apiSdk.postUsersSignup({}, data));
  },
  updateLanguage: async (data: { language: 'fr' | 'en' }) => {
    return await ApiRes.customFetch(() => apiSdk.patchUsersMeLanguage({}, data));
  },
  banUser: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.putUsersIdBan({ id }));
  },
  inviteUser: async (user: any, permissions: Permissions[]) => {
    return await ApiRes.customFetch(() => apiSdk.postUsers({}, [{ user, permissions }]));
  },
  importMultipleUsers: async (users: any) => {
    return await ApiRes.customFetch(() => apiSdk.postUsers({}, users));
  },
  activateAccount: async (password: string, token: string) => {
    return await ApiRes.customFetch(() => apiSdk.patchUsersActivateaccount({}, { token, password }));
  },
  putActivationData: async (id: string, data: any) => {
    return await ApiRes.customFetch(() => apiSdk.putUsersIdActivate({ id }, data));
  },
  updatePassword: async (id: string, data: any) => {
    return await ApiRes.customFetch(() => apiSdk.patchUsersIdUpdatepassword({ id }, data));
  },
  getAllUsers: async (params: any = {}) => {
    return await ApiRes.customFetch(() => apiSdk.getUsers({}, {}, params));
  },
  getUser: async (id: string) => {
    return await ApiRes.customFetch(() => apiSdk.getUsers({ id }, {}));
  },
  // TODO: data should be of type: Paths.PostUsersIdPermissions.RequestBody
  addPermission: async (id, data) => {
    return await ApiRes.customFetch(() => apiSdk.postUsersIdPermissions({ id }, { permissions: data }));
  },

  removePermission: async (id, data) => {
    return await ApiRes.customFetch(() => apiSdk.deleteUsersIdPermissions({ id }, { permissions: data }));
  },

  updateLabels: async (id, data) => {
    return await ApiRes.customFetch(() => apiSdk.putUsersIdLabels({ id }, { labels: data }));
  },
  setForgotPassword: async (email: string) => {
    return await ApiRes.customFetch(() => apiSdk.postForgotpassword({}, { email }));
  },

  resetPassword: async (token: string, newPassword: string) => {
    return await ApiRes.customFetch(() => apiSdk.postResetpassword({}, { newPassword, token }));
  },

  getPotentialEntityOwnersAutocomplete: async (searchTerm: string, resource: 'documents' | 'elearning') => {
    return await ApiRes.customFetch(() =>
      apiSdk.getUsersPotentialowners([
        { name: 'searchTerm', value: searchTerm, in: 'query' },
        { name: 'resource', value: resource, in: 'query' },
      ]),
    );
  },
};
