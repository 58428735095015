import { createSlice } from '@reduxjs/toolkit';

import { RevisionStatuses } from '@sbio/interfaces';

import { IRevisionsReducer } from './Revisions.model';

const initialState: IRevisionsReducer = {
  revision: null,
  pendingRevisions: null,
  approvedRevisions: null,
  declinedRevisions: null,
  draftRevisions: null,
  revisionsCount: {
    [RevisionStatuses.DRAFT]: null,
    [RevisionStatuses.APPROVED]: null,
    [RevisionStatuses.DECLINED]: null,
    [RevisionStatuses.PENDING]: null,
  },
};

export const revisionsSlice = createSlice({
  name: 'revisionsReducer',
  initialState,
  reducers: {
    SET_REVISION(state, action) {
      return {
        ...state,
        revision: action.payload,
      };
    },
    SET_PENDING_REVISIONS(state, action) {
      return {
        ...state,
        pendingRevisions: action.payload,
      };
    },
    SET_APPROVED_REVISIONS(state, action) {
      return {
        ...state,
        approvedRevisions: action.payload,
      };
    },
    SET_DECLINED_REVISIONS(state, action) {
      return {
        ...state,
        declinedRevisions: action.payload,
      };
    },
    SET_DRAFT_REVISIONS(state, action) {
      return {
        ...state,
        draftRevisions: action.payload,
      };
    },
    SET_REVISIONS_COUNT(state, action) {
      return {
        ...state,
        revisionsCount: action.payload,
      };
    },
  },
});

export const {
  SET_REVISION,
  SET_PENDING_REVISIONS,
  SET_APPROVED_REVISIONS,
  SET_DECLINED_REVISIONS,
  SET_DRAFT_REVISIONS,
  SET_REVISIONS_COUNT,
} = revisionsSlice.actions;

export const revisionsReducer = revisionsSlice.reducer;
