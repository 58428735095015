"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _exportNames = {};
var _analytics = _interopRequireWildcard(require("./analytics"));
Object.keys(_analytics).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _analytics[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _analytics[key];
        }
    });
});
var _classes = _interopRequireWildcard(require("./classes"));
Object.keys(_classes).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _classes[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _classes[key];
        }
    });
});
var _core = _interopRequireWildcard(require("./core"));
Object.keys(_core).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _core[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _core[key];
        }
    });
});
var _courses = _interopRequireWildcard(require("./courses"));
Object.keys(_courses).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _courses[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _courses[key];
        }
    });
});
var _document = _interopRequireWildcard(require("./document"));
Object.keys(_document).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _document[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _document[key];
        }
    });
});
var _errors = _interopRequireWildcard(require("./errors"));
Object.keys(_errors).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _errors[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _errors[key];
        }
    });
});
var _extractedText = _interopRequireWildcard(require("./extractedText"));
Object.keys(_extractedText).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _extractedText[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _extractedText[key];
        }
    });
});
var _learningPaths = _interopRequireWildcard(require("./learning-paths"));
Object.keys(_learningPaths).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _learningPaths[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _learningPaths[key];
        }
    });
});
var _logs = _interopRequireWildcard(require("./logs"));
Object.keys(_logs).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _logs[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _logs[key];
        }
    });
});
var _notifications = _interopRequireWildcard(require("./notifications"));
Object.keys(_notifications).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _notifications[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _notifications[key];
        }
    });
});
var _permissions = _interopRequireWildcard(require("./permissions"));
Object.keys(_permissions).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _permissions[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _permissions[key];
        }
    });
});
var _reports = _interopRequireWildcard(require("./reports"));
Object.keys(_reports).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _reports[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _reports[key];
        }
    });
});
var _resources = _interopRequireWildcard(require("./resources"));
Object.keys(_resources).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in exports && exports[key] === _resources[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _resources[key];
        }
    });
});
function _interopRequireWildcard(obj) {
    if (obj && obj.__esModule) {
        return obj;
    } else {
        var newObj = {};
        if (obj != null) {
            for(var key in obj){
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};
                    if (desc.get || desc.set) {
                        Object.defineProperty(newObj, key, desc);
                    } else {
                        newObj[key] = obj[key];
                    }
                }
            }
        }
        newObj.default = obj;
        return newObj;
    }
}
