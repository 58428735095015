"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.EducationLevel = exports.Duration = exports.Difficulty = exports.CourseStatuses = void 0;
var CourseStatuses;
exports.CourseStatuses = CourseStatuses;
(function(CourseStatuses) {
    CourseStatuses["PENDING"] = 'pending';
    CourseStatuses["ACTIVE"] = 'active';
    CourseStatuses["ARCHIVED"] = 'archived';
    CourseStatuses["REFUSED"] = 'refused';
    CourseStatuses["UNPUBLISHED"] = 'unpublished';
})(CourseStatuses || (exports.CourseStatuses = CourseStatuses = {}));
var Difficulty;
exports.Difficulty = Difficulty;
(function(Difficulty) {
    Difficulty["EASY"] = "EASY";
    Difficulty["MEDIUM"] = "MEDIUM";
    Difficulty["HARD"] = "HARD";
})(Difficulty || (exports.Difficulty = Difficulty = {}));
var Duration;
exports.Duration = Duration;
(function(Duration) {
    Duration["HALF_HOUR"] = "HALF_HOUR";
    Duration["ONE_HOUR"] = "ONE_HOUR";
    Duration["TWO_HOURS"] = "TWO_HOURS";
    Duration["MORE_TWO_HOURS"] = "MORE_TWO_HOURS";
})(Duration || (exports.Duration = Duration = {}));
var EducationLevel;
exports.EducationLevel = EducationLevel;
(function(EducationLevel) {
    EducationLevel["EVERYONE"] = "EVERYONE";
    EducationLevel["TECHNICIAN"] = "TECHNICIAN";
    EducationLevel["MASTER"] = "MASTER";
    EducationLevel["DOCTOR"] = "DOCTOR";
    EducationLevel["OTHER"] = "OTHER";
})(EducationLevel || (exports.EducationLevel = EducationLevel = {}));
