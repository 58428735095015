export class Snack {
  isDisplayed: boolean;
  message: string;
  type: 'info' | 'warning' | 'success' | 'error';
  duration: number;

  constructor(isDisplayed: boolean, message: string, type: 'info' | 'warning' | 'success' | 'error', duration = 8000) {
    this.isDisplayed = isDisplayed;
    this.message = message;
    this.type = type;
    this.duration = duration;
  }
}
