import { createTheme } from '@material-ui/core/styles';

const codecCold = {
  fontFamily: 'Codec Cold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Codec Cold'),
    local('Codec-Cold-Regular'),`,
};
const theme = createTheme({
  typography: {
    fontFamily: 'Codec Cold',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // @ts-expect-error: not sure why types are incompatible
        '@font-face': [codecCold],
      },
    },
  },
  palette: {
    primary: {
      main: '#003362',
    },
    secondary: {
      main: '#e83c4e',
    },
    warning: {
      main: '#E99921',
    },
    info: {
      main: '#003362',
    },
    error: {
      main: '#e83c4e',
    },
    success: {
      main: '#529E66',
    },
    text: {
      primary: '#003362',
      secondary: '#61616C',
    },
  },
});
export default theme;
