import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

import loggerMiddleware from './middleware/logger';
import { rootReducers } from './reducers';

const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['errorsReducer/SET_SNACKBAR'],
      },
    }).concat(loggerMiddleware, thunkMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
